@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap");
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  background-color: #bdc2cb;
}
a {
  text-decoration: none;
  color: #e65955;
}
a.ant-typography,
.ant-typography a {
  color: #e65955;
}
a:hover {
  color: #c12f2a;
}
.content-padding {
  padding: 30px;
}
.ant-breadcrumb a:hover {
  color: #c12f2a;
}
.ant-pagination-item-active a {
  color: #c12f2a;
}
.ant-pagination-item-active {
  border-color: #c12f2a;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #c12f2a;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ht-100v {
  height: 100vh;
}
.d-flex {
  display: flex !important;
}
.align-self-center {
  align-self: center !important;
}
.justify-content-center {
  justify-content: center !important;
}

.align-items-center {
  align-items: center !important;
}
.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.flex-column,
.flex-row {
  -webkit-box-direction: normal !important;
}
.flex-column {
  -webkit-box-orient: vertical !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.border-start {
  border-left: 1px solid #dee2e6 !important;
}
.auth {
  background-color: #fff;
}
.auth img {
  width: 200px;
  position: absolute;
  left: 30px;
  top: 60px;
}
.auth .form-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100%;
  padding: 0;
}
.auth .form-wrapper {
  margin: auto;
}
.auth .card-block {
  border: none;
  margin: auto;
  height: 100%;
  border-radius: 0;
  overflow: hidden;
  width: 100%;
  background-color: #fff;
}
.auth .card-block .bg-light {
  padding: 30px;
}
.bg-light h3 {
  font-size: 1.53125rem;
  font-weight: 700 !important;
}
.bg-light h1 {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.2;
}
.lead {
  font-size: 1.09375rem;
  font-weight: 400;
}
.text-center {
  text-align: center;
}
.list-inline,
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.list-inline-item {
  display: inline-block;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.ant-form-item {
  margin-bottom: 1rem;
}
.ant-form-item-label > label {
  height: auto;
  color: #000 !important;
}
.ant-btn {
  border-radius: 2px;
  font-size: 14px;
  height: auto;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #e65955;
  background: #fff;
  border-color: #e65955;
}
.ant-btn-primary {
  color: #fff;
  background-color: #e65955;
  border-color: #e65955;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff !important;
  background-color: #c12f2a;
  border-color: #c12f2a;
}
.ant-btn-primary:active {
  color: #fff;
  background-color: #c12f2a;
  border-color: #c12f2a;
}
@media (min-width: 768px) {
  .offset-md-1 {
    padding: 0 60px 0 40px;
  }
}
.text-right {
  text-align: right;
}
.after-login-logo {
  padding-top: 16px;
  padding-bottom: 16px;
  width: 60%;
}
.site-layout .ant-layout-header {
  background: #fff;
}
.ant-menu {
  font-size: 15px;
}
.ant-layout-sider {
  background: #0a1727;
  border-right: transparent;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  background: #e65955;
  color: #fff;
  border-radius: 5px 0px 0 5px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #e65955;
  color: #fff;
  border-radius: 5px 0px 0 5px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-selected {
  background: #e65955;
  color: #fff;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #e65955;
}
.list-user-action a {
  font-size: 14px;
  height: 18px;
  width: 18px;
  text-align: center;
  line-height: 18px;
  margin: 0 3px;
  background: rgba(255, 110, 64, 0.15);
  color: #e65955;
  display: inline-block;
  border-radius: 5px;
}
.ant-btn-text {
  font-size: 14px;
  height: auto;
  /* width: 25px; */
  text-align: center;
  line-height: 25px;
  margin: 0 3px;
  background: rgba(255, 110, 64, 0.15);
  color: #e65955;
  display: inline-block;
  border-radius: 5px;
  padding: 1px 6px;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  background: #f5f5f5;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 13px 20px;
  font-weight: normal;
}
.ant-table-thead > tr > th {
  background-color: transparent;
  color: #000000;
  font-weight: 600;
  font-size: 15px;
}
.ant-table,
.ant-progress-text,
.ant-select,
.ant-descriptions-item-label,
.ant-input-number,
.ant-input,
.ant-select-item,
.ant-picker-dropdown,
.ant-descriptions-item-content,
.ant-list,
.ant-breadcrumb,
.ant-tooltip,
.ant-message,
.ant-picker-input > input {
  font-size: 14px;
}

.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 11px;
  line-height: 11px;
  min-height: inherit;
}
.ant-input-number {
  width: 100% !important;
}
.production-master-modal .ant-picker {
  width: 100%;
}
/* .ant-select-selector {
  border-radius: 4px !important;
  padding: 0.275rem 0.2rem !important;
} */
.ml-20 {
  margin-left: 20px;
}
.ml-10 {
  margin-left: 10px;
}
.mt-12 {
  margin-top: 12px;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ant-card {
  border-radius: 8px;
}
.ant-card-body {
  /* padding: 0.5rem; */
}
/* .components-table-demo-nested .ant-table-content .ant-table-content {
  margin: 20px 0;
} */
.langselect .ant-select-selector {
  padding: 0 11px !important;
}
.langselect .ant-select {
  margin-right: 20px;
}
.ant-layout-header {
  padding: 0 20px;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr:not(:last-child)
  > th {
  border-bottom: 1px solid #f0f0f0;
}
@media only screen and (max-width: 575px) {
  .sm-mt-15 {
    margin-top: 15px;
  }
  .client-account-buttons {
    position: relative !important;
    text-align: right;
  }
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 3px 5px;
}
.activitycode .ant-col {
  height: 100%;
  padding: 0 !important;
}
.activitycode .ant-row {
  margin: 0 !important;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 3px 5px;
  height: 100%;
  margin-bottom: 0;
  border-right: 1px solid #f0f0f0 !important;
  border-bottom: 1px solid #f0f0f0;
}
.activitycode .ant-list-bordered {
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  border-right: none;
  border-bottom: none;
}
.dailyupdate .ant-divider-horizontal {
  margin: 0;
}
.listofpersons .ant-col {
  padding: 5px;
}
@media only screen and (min-width: 768px) {
  .activitycode,
  .comments {
    padding-right: 30px;
    border-right: 1px solid #f0f0f0;
  }
  .colorcode,
  .listofpersons {
    padding-left: 10px;
  }
}
.activitycode,
.colorcode,
.comments,
.listofpersons {
  padding-top: 5px;
  padding-bottom: 5px;
}
.production-calendar .ant-form-item,
.work-order-new .ant-form-item {
  margin-bottom: 0;
}
/* .ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: inherit;
} */
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding: 0 !important;
}

.langselect .ant-select {
  line-height: 2.4;
}
.ant-layout-header,
.ant-layout-header {
  height: 70px;
  line-height: 70px;
}
.productioncalender-search {
  position: absolute;
  left: calc(50% - 90px);
  z-index: 999;
  top: -57px;
}
.productioncalender-search1 {
  position: absolute;
  right: 250px;
  z-index: 999;
  top: -34px;
}
.ant-table {
  color: rgba(0, 0, 0, 1);
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #f7f7f7;
}

.ant-form-item-control-input {
  min-height: inherit;
}
.ant-layout-footer {
  padding: 10px 5px;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f2efef;
  border-color: #adadad !important;
}
.ant-form label {
  font-size: 14px;
}
.ant-picker-year-panel .ant-picker-content {
  height: auto;
}
.ant-select:hover .ant-select-clear {
  opacity: 0;
}
.ant-input-sm {
  padding: 0px 5px;
}
.newworder-latest .ant-btn-text {
  font-size: 12px;
  height: 18px;
  width: 18px;
  line-height: 18px;
  padding: 0;
}
.ant-menu-inline-collapsed {
  width: 60px;
}
.table-head-new .ant-table-thead > tr > th {
  font-size: 10px;
}
.ant-table-row-expand-icon {
  float: none;
}
.ant-table .ant-table-expanded-row-fixed {
  padding: 0px 16px;
  margin: 5px -17px !important;
}
.collapsed-logo {
  display: none;
  padding: 19px 0;
}
.normal-logo {
  display: inline-block;
}
.ant-layout-sider-collapsed .collapsed-logo {
  display: inline-block;
}
.ant-layout-sider-collapsed .normal-logo {
  display: none;
}
.table-head-new td {
  background: #fff;
}
.production-calendar .ant-table-tbody .editable-field {
  background: #feece7 !important;
}

.dashboard-new .ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 46px);
  padding-right: calc(2em + 45px);
}
.work-order-new
  .ant-table-tbody
  .ant-table-container
  .ant-table-thead
  > tr
  > th {
  font-size: 10px;
}
@media (max-width: 767px) {
  .productioncalender-search {
    position: relative;
    top: 0;
    left: 12px;
    width: 100%;
    margin-top: 5px;
  }
  .productioncalender-search1 {
    position: relative;
    top: 0;
    left: 12px;
    right: 0;
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (max-width: 1199px) and (min-width: 768px) {
  .productioncalender-search {
    left: 44px;
  }
}
@media (min-width: 1200px) {
  .dashboard-new .ant-table-body {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.adddailyupdate .ant-card {
  margin-bottom: 0px;
  border: none;
}
.desousamain {
  /* margin-top: 15px; */
}
.setting-btn {
  margin-bottom: 10px;
}
.project-list .ant-col {
  margin-bottom: 10px;
}
@media only screen and (min-width: 767px) {
  .setting-btn {
    margin-bottom: 15px;
    text-align: right;
    padding-right: 20px;
  }
  .patrolling-btn {
    top: -9px;
  }
  .project-list {
    position: absolute;
    top: -83px;
    left: 0px;
    width: 58%;
    margin-bottom: 0px;
  }
}
.ant-menu-submenu-selected {
  color: #cf504d;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #cf504d;
}
.ant-tabs-tab:hover {
  color: #cf504d;
}
.ant-tabs-ink-bar {
  background: #cf504d;
}
.ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #fff;
}
/* .ant-card-head {
  color: #fff;
  background: #0a1727;
} */
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #fff;
}
.login-new-bg {
  background: url(auth-bg.jpg) no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.ant-menu {
  color: #fff;
  background: transparent;
  padding-left: 20px;
  font-weight: 500;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  color: #e65955;
}
.ant-menu-sub.ant-menu-inline {
  background: #1f2935;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: transparent;
}
.new-logo {
  background: #fff;

  text-align: center;
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #1f2935;
}
.ant-menu-vertical .ant-menu-item {
  margin-top: 0;
  margin-bottom: 0;
}
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .new-md-none {
    display: none;
  }
  .md-mb-10 {
    margin-bottom: 10px;
  }
}
.dailyupdate-tab
  .ant-card-small
  > .ant-card-head
  > .ant-card-head-wrapper
  > .ant-card-head-title {
  padding: 0;
}
.dailyupdate-tab .ant-card-small > .ant-card-head {
  padding-right: 0;
}
.dailyupdate-tab
  .ant-card-small
  > .ant-card-head
  > .ant-card-head-wrapper
  > .ant-card-extra {
  padding: 0;
}
.ant-btn {
  padding: 7px 16px;
  border-radius: 4px;
}
.ant-card-body {
  padding: 21px 20px;
}
.ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper.materialtable:only-child
  .ant-table {
  margin: -9px -16px 6px 33px;
}
.ant-btn-link {
  padding: 0;
  color: #e65955;
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
  background: transparent;
}
.ant-card-small > .ant-card-body {
  padding: 0;
}
.collpsenew-button {
  position: absolute;
  top: -49px;
  right: 15px;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
  border: none;
  border-top: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 18px 20px;
  padding-left: 40px;
  color: #000;
  background: #fff;
  font-weight: 600;
  border-left: none;
  font-size: 18px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  padding: 21px 16px;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.ant-empty-normal {
  margin: 0px 0;
}
.mt-30 {
  margin-top: 30px;
}
.ant-collapse {
  border-left: none;
  border-right: none;
}
.mb-10 {
  margin-bottom: 10px;
}
.dailyupdate-tab {
  /* margin-bottom: 50px; */
}
.adddailyupdatedashboard .ant-radio-group {
  display: none;
}
.calendarcell .ant-btn {
  padding: 0 3px;
  line-height: initial;
}
.adddailyupdatedashboard
  .ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-calendar-date-content {
  height: 64px;
}
.adddailyupdatedashboard
  .ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-calendar-date {
  margin: 0;
  position: relative;
}
.calendar-update {
  margin-bottom: 5px;
  font-size: 10px;
}
.update-dot {
  width: 100%;
  height: 3px;
  background-color: #52c41a;
  position: absolute;
  top: -3px;
  left: 0;
}
@media (max-width: 767px) {
  .calendar-update span {
    display: none;
  }
  .padding-33 {
    padding-left: 0 !important;
  }
  .content-padding {
    padding: 10px;
  }
  .adddailyupdate h5 {
    font-size: 16px !important;
  }
}
@media (min-width: 768px) {
  .adddailyupdatedashboard .ant-picker-calendar-header {
    position: absolute;
    top: 13px;
    right: 25px;
  }
}
.adddailyupdatedashboard .ant-picker-cell {
  border: solid 2px #eee;
}
/* .calendarcellmodal .ant-modal-body {
  padding: 0;
} */
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.p-12 {
  padding: 12px;
}
label {
  text-align: left !important;
}
.breakpoint .ant-card-extra a {
  font-size: 32px;
}
.breakpoint .ant-card-head {
  font-weight: 600;
  border-left: none;
  font-size: 18px;
  padding: 18px 20px;
}
.card-body-0 .ant-card-body {
  padding: 1px 0 0px 0;
}
.breakpoint .ant-card-head-title {
  padding: 0;
}
.breakpoint .ant-card-extra {
  padding: 0;
  position: absolute;
  right: 20px;
}
.breakpoint .ant-form-item {
  margin-bottom: 0;
}
.breakpoint-top p {
  font-weight: 600;
  padding-top: 3px;
  text-align: right;
  padding-right: 10px;
}
.mr-2 {
  margin-right: 10px;
}
.drawing-component {
  position: relative;
}
.drawing-btn .ant-btn {
  padding: 2px 5px;
  font-size: 14px;
  line-height: initial;
  color: #e65955;
  border: none;
}
.padding-33 {
  padding-left: 33%;
}

.grid-table {
  border: 1px solid #f0f0f0;
  border-bottom: none;
  border-right: none;
}
.sigdiv1 {
  padding: 8px;
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  height: 100%;
}
.cdiv {
  height: 30px;
  display: block;
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  text-align: center;
  padding-top: 4px;
}
.block1 {
  text-align: center;
}
.ncdiv {
  padding: 8px;
  display: block;
  height: 100%;
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
}

.additem {
  border: dashed 2px #eee;
  padding: 12px;
  cursor: pointer;
  background: #fff;
  position: relative;
}
.additem p {
  margin-bottom: 0;
}

.chooseitem-list {
  padding: 0;
  margin: 0;
}
.chooseitem-list li {
  list-style: none;
  display: block;
}
.chooseitem-list li a {
  background: #fff;
  border-radius: 3px;
  display: block;
  padding: 11px;
  color: #000;
  margin-bottom: 12px;
}
.pavimg img {
  width: 64px;
  height: 64px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.create-quote .ant-steps {
  background: #f9faff;
  padding: 24px;
}
.create-quote .ant-card-body {
  padding: 15px !important;
}
.create-quote .steps-content {
  min-height: 200px;
  margin-top: 20px;
}

.create-quote .steps-action {
  margin-top: 14px;
}
.adddailyupdate h5 {
  font-size: 20px;
  font-weight: 500;
}
.create-workdescription .ant-card {
  background: #f9faff;
}

.create-quote .work-preview {
  background: #f9faff;
  padding: 15px;
}
.create-workdescription .ant-card-head,
.create-quote .work-preview .ant-card-head {
  color: #000;
  font-weight: 600;
  background: transparent;
}
.workpreview-top {
  background: #f9faff;
  padding: 32px;
  margin-bottom: 10px;
}
.workpreview-top h5 {
  margin-bottom: 0;
}
.iagree {
  border-top: 1px solid #f0f0f0;
  padding: 14px 14px 0 14px;
  margin-top: 20px;
}
.pricebreakup-table .ant-table-thead > tr > th {
  background: none;
  color: #6b7280;
  text-transform: uppercase;
  font-size: 11px;
  border-bottom: none;
  padding: 0.2rem 5px;
}
.pricebreakup-table .ant-table-tbody > tr > td {
  border-bottom: none;
  padding: 0.2rem 5px;
}
.pricebreakup-table .ant-table-summary > tr > td {
  border-bottom: none;
  padding: 0.2rem 5px;
}
.viewpdf {
  color: #6b7280;
  margin-top: 10px;
}
.mt-5 {
  margin-top: 5rem;
}
.quotes .ant-btn {
  padding: 4px 15px;
}
.pat-tab .ant-form-item {
  margin: 0;
  height: 100%;
}
.pat-tab .ant-form-item-control-input {
  /* min-height: inherit; */
  height: 100%;
}
.pat-tab .ant-form-item-control-input-content {
  height: 100%;
}
.ant-upload.ant-upload-select-picture-card {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border: 1px dashed #c2c8dc;
}
.ant-upload-list-picture-card-container {
  width: 80px;
  height: 80px;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0;
}
.image-upload .ant-btn:hover,
.image-upload .ant-btn:focus {
  color: inherit;
  background: transparent;
  border-color: transparent;
}
.image-upload .ant-btn-text {
  background: transparent;
}
*/ .addworkdescription p.mb-0 {
  margin-top: 10px;
}
.wdtext-center .ant-form-item-label {
  text-align: center;
}
.new-project-mobile {
  display: none;
}
.new-project-desktop {
  display: block;
}
@media (max-width: 991px) {
  .new-project-mobile {
    display: block;
  }
  .new-project-mobile .ant-btn {
    padding: 4px 15px;
    box-shadow: none;
    border-radius: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .new-project-desktop {
    display: none;
  }
}

.project-list-new-component {
  margin: 0;
  padding: 0;
  padding-left: 10px;
}
.project-list-new-component li {
  padding-bottom: 10px;
  list-style: square;
}

.line-row {
  border-bottom: 1px solid #f0f0f0;
}
.Applydiscount .ant-modal-body {
  padding-bottom: 0;
}
.Applydiscount .ant-modal-footer {
  border-top: none;
  text-align: center;
  padding-bottom: 24px;
  padding-top: 0;
}
.text-green {
  color: #7cb342;
}
.image-upload .ant-upload-list-picture-card .ant-upload-list-item-info {
  background: #fff;
}

.line-row {
  border-bottom: 1px solid #f0f0f0;
}
.Applydiscount .ant-modal-body {
  padding-bottom: 0;
}
.Applydiscount .ant-modal-footer {
  border-top: none;
  text-align: center;
  padding-bottom: 24px;
  padding-top: 0;
}
.text-green {
  color: #7cb342;
}
.image-upload .ant-upload-list-picture-card .ant-upload-list-item-info {
  background: #fff;
}
.drawing-save {
  position: absolute;
  right: 0;
}
.drawing-save .ant-btn {
  padding: 2px 7px;
}
.estimate-buttons-right {
  position: absolute;
  right: 24px;
  top: 18px;
}
.estimate-buttons-right .viewpdf {
  display: inline-block;
  border-top: none;
  margin-top: 0;
  padding-top: 0;
  padding-left: 0;
}
@media screen and (max-width: 620px) {
  .estimate-buttons-right {
    position: relative;
    right: inherit;
    top: 0px;
    text-align: right;
    vertical-align: top;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 460px) {
  .estimate-buttons-right {
    text-align: center;
  }
  .estimate-buttons-right .viewpdf {
    display: block;
    margin-bottom: 10px;
  }
}
.accounting-sub-contrctors {
  border: solid 1px #eee;
}
.accounting-sub-contrctors h3 {
  background-color: #6b6b6b;
  padding: 3px;
  text-align: center;
  color: #fff;
}
.accounting-sub-contrctors {
  background-color: #f9f9f9;
  margin-bottom: 10px;
}
.accounting-sub-contrctors ul {
  padding: 0;
  margin: 0;
}
.accounting-sub-contrctors li {
  padding: 5px 7px;
  border-bottom: solid 1px #eee;
  list-style: none;
  text-align: left;
}
.accounting-sub-contrctors li a {
  color: #000;
}
.accounting-sub-contrctors li a:hover {
  color: #e65955;
}
.accounting-summary h3 {
  background-color: #6b6b6b;
  padding: 3px;
  text-align: center;
  color: #fff;
}

.client-account-details {
  position: relative;
}
.client-account-buttons {
  position: absolute;
  top: -6px;
  right: 0;
}
.accounting-summary .ant-table-tbody > tr > td {
  padding: 0.1rem 1rem;
  font-size: 13px;
}
.client-account-details .ant-table-tbody > tr > td {
  padding: 0.3rem 0.5rem;
  font-size: 13px;
}
.client-account-details .ant-table-thead > tr > th {
  padding: 0.3rem 0.5rem;
  font-size: 13px;
}
.assignSubcontractor .ant-collapse-content > .ant-collapse-content-box {
  position: relative;
}
.button-contractor-save {
  position: absolute;
  right: 0;
  top: -36px;
}
.new-project-desktop
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #e9f3ff;
  border: 1px solid transparent;
}
.ant-select {
  border-radius: 4px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 36px;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 34px;
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 20px;
  font-size: 20px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 49px;
  line-height: 49px;
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 0px);
  padding-left: 15px !important;
}
.project-heading-menu {
  color: #fff;
  opacity: 0.5;
  margin-bottom: 20px;
  font-size: 14px;
  padding-left: 15px;
}
.ant-collapse {
  background-color: transparent;
  border: none;
}
.ant-collapse-content {
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
/* .ant-table-tbody > tr > td {
  border-bottom: none;
} */
.dailyupdate-tab .ant-table-thead > tr > th {
  border-bottom: none;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: none;
  margin-bottom: 20px;
}
.collpsenew-button .ant-btn {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 0;
  font-size: 16px;
}
.save-notes-btn {
  position: absolute;
  right: 29px;
  bottom: calc(50% - 11px);
}
.anticon-edit {
  color: #258afe;
}
.ant-table-tbody tr:nth-child(even) {
  background-color: #fbfdff;
}
.ant-modal-content {
  background-color: #eef2f6;
}
.ant-modal-footer {
  background-color: #fff;
  text-align: center;
  padding: 19px 16px;
}
.ant-modal-title {
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
  font-size: 20px;
}
.ant-modal-header {
  padding: 28px 56px;
  background: transparent;
  border-bottom: none;
  border-radius: 0;
  padding-bottom: 0;
}
.ant-modal-body {
  padding: 28px 56px;
}
@media (min-width: 768px) {
  .ant-modal-close {
    position: absolute;
    top: 7px;
    right: 33px;
    color: #0a1727;
  }
}

.ant-modal-body .ant-form {
  background: #fff;
  padding: 29px;
  border-radius: 10px;
}
.ant-input {
  padding: 6px 11px;
  border-radius: 4px;
}
.ant-input-number-input {
  height: 36px;
  border-radius: 4px;
}
.ant-picker {
  padding: 6px 11px 6px;
  border-radius: 4px;
}
.patrolling-new .ant-card-body {
  padding: 3px 0 24px 0;
}
.ant-table-thead > tr > th {
  border-bottom: 1px solid #f0f0f0;
}
.settings-table .ant-card-body {
  padding: 0;
}
.settings-table .ant-tabs-tab {
  padding: 18px;
  margin: 0;
}
.settings-table .ant-table-thead > tr > th {
  border-top: 1px solid #f0f0f0;
}
.ant-card-body .ant-table-pagination.ant-pagination {
  padding-right: 20px;
  padding-left: 20px;
}
.quotes-tablist .ant-tabs-nav {
  background: #fff;
  padding: 15px 20px 0 20px;
}
.salesquote-card {
  border-radius: 8px 8px 0 0;
}
.ant-menu-inline-collapsed {
  padding-left: 0 !important;
}
.ant-menu-inline-collapsed .project-heading-menu {
  opacity: 0;
}
.new-datepicker {
  background: #f9faff;
  padding: 9px 19px;
  border-radius: 10px;
}
.ant-picker-input > input {
  font-weight: 600;
}
.breakpoint .card1 .ant-card-body {
  padding: 12px 20px;
}
.breakpoint .card1 .ant-picker {
  padding: 0;
}
.adddailyupdate .card1 h5 {
  padding-top: 14px !important;
}
.dailyupdate-tab .card2 .ant-picker {
  padding: 0;
}
.dailyupdate-tab .card2 .ant-breadcrumb {
  padding-top: 5px;
}
@media (max-width: 767px) {
  .collpsenew-button .ant-btn {
    width: 24px;
    height: 24px;
  }
  .collpsenew-button {
    top: -46px;
  }
  .new-datepicker {
    margin-top: 10px;
  }
  .breakpoint .ant-card-extra a {
    font-size: 24px;
  }
  .adddailyupdate .card1 h5 {
    padding-top: 0 !important;
  }
  .ant-modal-header,
  .ant-modal-body {
    padding: 15px 20px;
  }
  .setting-btn {
    margin-bottom: 15px;
    text-align: right;
    padding-right: 20px;
    padding-left: 20px;
  }
  .quotes .ant-btn {
    padding: 4px 9px;
  }
}
.extra-form-item .ant-form-item {
  margin-bottom: 0;
}
.patrollingTime .ant-picker-footer .ant-btn {
  padding: 3px 11px !important;
}
